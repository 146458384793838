import React from "react"
import SEO from "../components/SEO";
import { Link } from "gatsby"
import gray from "gray-percentage"
import { injectGlobal, styled } from '@emotion/styled'
import theme from '../utils/constants'
import '../../dist/vendor/bootstrap/css/bootstrap.min.css'

import "typeface-rochester"
import "typeface-josefin-sans"
import "typeface-josefin-slab"

import { rhythm, scale } from "../utils/typography"
import Container from "../components/container"

import TopNav from "../components/topnav"
import { left } from "glamor";

class DefaultLayout extends React.Component {
  render() {
    return (
      <div css={{
        background: 'white',
      }}>
        <TopNav />
        <header>
          <SEO />
        </header>
        <div css={{
              marginBottom: 120,
        }}>
          {this.props.children}
        </div>
        <footer css={{ 
          background: theme.paleYellow,
          margin: "auto",
          bottom: 0,
          height: 100,
          width: "100%",
         }}>
          <Container>
            <div css={{ maxWidth: `50%`, float: `left` }}>
            </div>
            <div
              css={{ float: `right`, maxWidth: `50%`, paddingLeft: rhythm(1) }}
            >
              Copyright spyderboy {new Date().getFullYear()}
            </div>
          </Container>
        </footer>
      </div>
    )
  }
}

export default DefaultLayout
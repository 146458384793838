import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"
import Container from "../components/container"

const AllPages = ({ data }) => (
  <Layout>
    <Container>
      <h1>Pages</h1>
      <ul>
{/*         {data.allNodePage.nodes.map(({ title }) => (
          <li>
          </li>
        ))} */}
      </ul>
    </Container>
  </Layout>
)

export default AllPages

/* export const query = graphql`
  query {
    allNodePage(limit: 1000) {
      nodes {
        title 
      }
    }
  }
` */
import React, { Fragment } from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';

import { Link } from 'gatsby'
import Img from "gatsby-image"

import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Headroom from 'react-headroom'
import { StaticQuery, graphql } from "gatsby"

const colors = {
  black: {
    base: '#333438',
    light: '#4b4e57',
    lighter: '#696d77',
    blue: '#2e3246',
  }
}
const transition = {
  easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  easeOutBack: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  duration: '0.4s',
}
const theme = {
  colors,
  transition,
}

const StyledLink = styled(Link)`
  font-weight: 700;
  align-items: center;
  padding: 20px;
`
const StyledLogo = styled.img`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 96px;
  width: 120px;
`

const StyledHeadroom = styled(Headroom)`
`

const underline = css`
  text-decoration: underline;
`


export default () => (
  <StaticQuery 
    query = {graphql`
      query LogoQuery {
        logo: file(relativePath: { eq: "spyderlogo.png" }) {
          childImageSharp {
            fluid(maxWidth:350) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `} 
    render = { data => (
      <Fragment>
    <StyledHeadroom>
    <Navbar bg="white" expand="lg">
      <Navbar.Brand href="/">
        <Img fluid={data.logo.childImageSharp.fluid} alt='Spyderboy Logo' />
        spyderboy
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
        </li>
      </ul>
      </Navbar.Collapse>
    </Navbar>
    </StyledHeadroom>
  </Fragment>
    )}
  />
)
